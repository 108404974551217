import React from "react";
import RegisterForm from "../components/signUp/registerForm";

const SignUp = () => {
  return (
    <div>
      <RegisterForm />
    </div>
  );
};

export default SignUp;
